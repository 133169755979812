import request from '@/core/services/api.services.request'

export function getCustomerOrder(customer_id) {
    return request({
      url: '/packingslip_customer/order/'+customer_id,
      method: 'get',
    })
  }

  // export function getOrderList(order_id) {
  //   return request({
  //     url: '/packingslip_order/list/'+order_id,
  //     method: 'get',
  //   })
  // } 

  export function getOrderList(order_id) {
    return request({
      url: '/delivery/packing/list/'+order_id,
      method: 'get',
    })
  } 
  export function getPackingslipList(params) {
    return request({
      url: '/packingslip/list',
      method: 'get',
      params: params
    })
  }

  export function getPackingSlip(packing_id) {
    return request({
      url: '/packingslip/view/'+packing_id,
      method: 'get',
    })
  }

  export function deletePacking(packing_id) {
    return request({
      url: '/packingslip/delete/'+packing_id,
      method: 'delete',
    })
  }

  export function downloadPdf(id,type) {
    return request({
      url: '/pdf/create/' + id +'/' + type,
      method: 'get',
      responseType: 'blob',
    })
  }

  export function getPackingCustomer(customer_id) {
    return request({
      url: '/customer/packingslip/'+customer_id,
      method: 'get',
    })
  }
  export function getPackingOrder(order_id) {
    return request({
      url: '/customer/order/packingslip/'+order_id,
      method: 'get',
    })
  }
  
